<template>
  <div v-if="types">

    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="publication.title"
          placeholder="Title"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <span>
          The title can be used to identify the work, to place it in context, to convey a minimal summary of its contents, and to pique the reader's curiosity.
        </span>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-select
          v-model="publication.type"
          placeholder="Type"
          outlined
          :items="types"
          :loading="types.length === 0"
          item-text="displayName"
          item-value="name"
        ></v-select>
      </v-col>
      <v-col cols="6">
        <span>
           Publication Type is the term used to classify the type of articles published in journals.
        </span>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-combobox
          v-model="units"
          placeholder="Units"
          outlined
          :items="allProjects"
          item-text="displayTitle"
          multiple
          chips
          deletable-chips
        ></v-combobox>
      </v-col>
      <v-col cols="6">
        <span>
           Select all project partners including yourself which worked on this publication.
        </span>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="publication.doi"
          placeholder="DOI"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <span>
          The DOI of your publication.
        </span>
      </v-col>
    </v-row>

    <v-divider />
    <div class="d-flex justify-end pt-3">
      <v-btn
        color="success"
        :disabled="!isReady"
        @click="validateAndNext"
      >
        Continue
      </v-btn>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, computed, inject} from "vue";
import {getAllPublicationTypes} from "@/modules/publication-next/api/publication";
import {useState} from "@/modules/publication-next/composeables/se2a-publication-create-state";
import {useGetters} from "vuex-composition-helpers";

export default defineComponent({
  name: "se2a-publication-create-general",
  setup() {
    const { publication, types, units } = useState();
    const next = inject('next');

    onMounted(() => {
      getAllPublicationTypes().then(result => {
        types.value = result.asPaginationResult().membersData();
      }).catch(() => {
        console.log("Error while fetching publication types");
      })
    });

    const unit = useGetters('unit', ['items']);
    const allProjects = computed(() => {
      return unit.items.value
        .filter(unit => unit.unitType.name === 'project')
        .map(u => {
          return {
            displayTitle: u.projectPhase + ': ' + u.shortName,
            ...u
          }
        })
    })

    const isReady = computed(() => {
      return publication.value.title !== undefined &&
        publication.value.title.length > 5 &&
        publication.value.type !== undefined &&
        publication.value.type !== "" &&
        units.value !== undefined &&
        units.value.length >= 1;
    })

    function validateAndNext() {
      next();
    }

    return {
      isReady,
      publication,
      types,
      validateAndNext,
      allProjects,
      units
    }
  }
});
</script>

<style scoped>

</style>
