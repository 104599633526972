<template>
  <div>

    <div id="title">
      <h2><v-chip>{{ publication.metadata.status }}</v-chip> {{publication.title}}</h2>
    </div>

    <div id="fields">
      <div v-for="(index, value) in publication.fields" v-bind:key="index">
        <b>{{ value }}:</b> {{ index }}
      </div>
    </div>

    <v-divider />
    <div id="goals">
      <div v-for="(index, value) in goals" v-bind:key="index + value">
        <b>{{ value }}:</b> {{ index }}
      </div>
    </div>



    <v-divider />
    <div class="d-flex justify-end pt-3">
      <v-btn
        color="success"
        @click="submit"
      >
        Submit
      </v-btn>
    </div>

    <v-snackbar v-model="importError">
      Error while submitting publication: {{ importErrorDesc }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="importError = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {CLIENT} from "@/api/client";
import {useState} from "@/modules/publication-next/composeables/se2a-publication-create-state";
import {useRouter} from "vue2-helpers/vue-router";
import {ref} from "vue";

export default {
  name: "se2a-publication-create-review",
  setup() {
    const {publication, units, goals, reset} = useState();
    const router = useRouter();

    const importError = ref(false);
    const importErrorDesc = ref("");

    const submit = () => {
      CLIENT.go('/api/publications').post({
        title: publication.value.title,
        doi: publication.value.doi,
        type: publication.value.type,
        fields: publication.value.fields,
        goals: goals.value,
        metadata: publication.value.metadata
      }).then(value => {
        router.push({name: 'publication-create-success'})
        reset();
      }).catch(e => {
        importErrorDesc.value = e;
        importError.value = true;
      })
    }

    return {
      publication, goals, submit, importError, importErrorDesc
    }
  }
}
</script>

<style scoped>

</style>
