import {CLIENT} from "@/api/client";
import {byApi} from "@/api/helpers";

export function findPublication(id: Number) {
  return CLIENT.go('/api/publications/' + id).get();
}

export function importPublication(doi: string) {
  return CLIENT.go('/api/publications/doi').post({doi: doi});
}

export function getAllPublicationTypes() {
  return CLIENT.go('/api/publication_types').get();
}

export function saveNormalizedPublication(normalizedPublication) {

  let publication = {
    doi: normalizedPublication['doi'] ?? '',
    fields: normalizedPublication['fields'] ?? {},
    goals: normalizedPublication['goals'] ?? [],
    metadata: normalizedPublication['metadata'] ?? {},
    title: normalizedPublication['title'] ?? '',
    type: normalizedPublication['type']['@id'],
    workline: normalizedPublication['workline'] ?? ''
  }

  /**
   * Temporärer fix; Weil mitternacht :c
   */
  if(normalizedPublication.workline == 'unclear')
    publication = {
      ...publication,
      workline: 'start'
    }


  return CLIENT.go(normalizedPublication['@id']).patch(publication);
}

export function deletePublicationByObject(normalizedPublication) {
  return CLIENT.go(normalizedPublication['@id']).delete();
}

export function verifyPublicationByObject(normalizedPublication) {
  return CLIENT.go('/api/publications/verify').post({
    iri: normalizedPublication['@id'],
    verified: true
  })
}

export function includedInFprPublicationByObject(normalizedPublication) {
  return CLIENT.go('/api/publications/includedInFpr').post({
    iri: normalizedPublication['@id'],
    includedInFpr: true
  })
}

export function setWorklineByPublicationObject(normalizedPublication, workline) {
  return CLIENT.go(normalizedPublication['@id']).patch({
    workline: workline
  })
}


export function getGoalsByPublication(normalizedPublication) {
  return CLIENT.go(normalizedPublication['@id'] + '/goals').get();
}

export function getPublicationGoals() {
  return CLIENT.go(byApi('publication_goals')).get();
}
