<template>
  <div class="pt-2" style="height: 50vh">
    <iframe height="100%" width="100%" src="/publications/SE2A_PublicationPolicy_2021-03-31_final.pdf"></iframe>
  </div>
</template>

<script>
export default {
  name: "se2a-publication-policy"
}
</script>

<style scoped>

</style>
