<template>

<div v-if="typeof activeType !== 'undefined'">
  <se2a-publication-field-editor
    title="Required Fields"
    :fields="activeType.requiredFields"
    :model="publication.fields"
  />

  <se2a-publication-field-editor
    title="Optional Fields"
    :fields="activeType.optionalFields"
    :model="publication.fields"
  />

  <v-divider />
  <div class="d-flex justify-end pt-3">
    <v-btn
      color="success"
      @click="validateAndNext"
    >
      Continue
    </v-btn>
  </div>
</div>

</template>

<script>
import {defineComponent, inject, reactive} from "vue";
import {useState} from "@/modules/publication-next/composeables/se2a-publication-create-state";
import Se2aPublicationFieldEditor
  from "@/modules/publication-next/component/se2a-publication-field-editor/se2a-publication-field-editor";

export default defineComponent({
  name: "se2a-publication-create-fields",
  components: {Se2aPublicationFieldEditor},
  setup() {
    const { publication, types, activeType } = useState();
    const next = inject('next');

    const validateAndNext = () => {
      next();
    }

    return {
      publication,
      types,
      activeType,
      validateAndNext
    }
  }
});
</script>

<style scoped>

</style>
