<template>
  <div>
    <v-card>
      <v-card-text>
        <div class="mb-2">
          <h3>Add your publications to the se<sup>2</sup>a publication database</h3>
        </div>

        <v-divider/>

        <v-row class="mt-3 mb-3">
          <v-col cols="6" class="d-flex flex-column justify-space-between">
            <div>
              <p>Insert a publication and all its metadata by hand</p>
            </div>
            <div class="mb-2 d-flex justify-center">
              <v-btn @click="createPublicationManual">Insert Publication</v-btn>
            </div>
          </v-col>
          <v-divider vertical/>
          <v-col cols="6" class="d-flex flex-column justify-space-between">
            <div>
              <p>Import your publications metadata by using a DOI. For example "10.3390/aerospace8060163"</p>
            </div>
            <div>
              <v-text-field
                placeholder="Use your publication's DOI to import"
                v-model="doi"
              />
              <div class="d-flex justify-center">
                <v-btn
                  :disabled="doi.length === 0"
                  :loading="importRunning"
                  :color="importError ? 'red' : 'success'"
                  @click="createPublicationImport"
                >Import Publication
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <se2a-publication-policy/>

    <v-snackbar v-model="importError">
      Error while importing publication
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="importError = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {defineComponent, ref, set, computed, inject} from "vue";
import Se2aPublicationPolicy from "@/modules/publication-next/component/se2a-publication-policy/se2a-publication-policy";
import {importPublication} from "@/modules/publication-next/api/publication";

import { useState } from '@/modules/publication-next/composeables/se2a-publication-create-state';

export default defineComponent({
  name: "se2a-publication-create-welcome",
  components: {Se2aPublicationPolicy},
  setup() {
    const doi = ref("");
    const { publication, reset } = useState();
    const next = inject('next');

    function createPublicationManual() {
      reset();
      next();
    }

    const importRunning = ref(false);
    const importError = ref(false);
    function createPublicationImport() {
      reset();
      importRunning.value = true;
      importPublication(doi.value).then(result => {
        publication.value = result.rawData['data'];

        // we do not have metadata at this stage! convert to object.
        publication.value.metadata = {};
        next();
      }).catch(reason => {
        importError.value = true;
      }).finally(() => {
        importRunning.value = false;
      })
    }

    return {
      publication,
      doi,
      createPublicationManual,
      importError,
      importRunning,
      createPublicationImport
    }
  }
});
</script>

<style scoped>

</style>
