<template>
  <div>

    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">
            Unit Name
          </th>
          <th class="text-center">
            Non-peer review journals
          </th>
          <th class="text-center">
            peer review journals
          </th>
          <th class="text-center">
            Q1/A publication
          </th>
          <th class="text-center">
            cross-project-publication
          </th>
        </tr>
        </thead>
        <tbody>

        <tr
          v-for="unit in units"
          :key="unit.id"
        >
          <td>{{ unit.shortName }} - {{ unit.title }}</td>

          <td>
            <div class="d-flex justify-center">
              <v-checkbox value="non-peer-publication"
                          v-model="goals[unit.id]"
              ></v-checkbox>
            </div>
          </td>
          <td>
            <div class="d-flex justify-center">
              <v-checkbox value="peer-review-publication"
                          v-model="goals[unit.id]"
              ></v-checkbox>
            </div>
          </td>
          <td>
            <div class="d-flex justify-center">
              <v-checkbox value="q1-a-publication"
                          v-model="goals[unit.id]"
              ></v-checkbox>
            </div>
          </td>
          <td>
            <div class="d-flex justify-center">
              <v-checkbox value="cross-project-publication"
                          v-model="goals[unit.id]"
              ></v-checkbox>
            </div>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-divider />
    <div class="d-flex justify-end pt-3">
      <v-btn
        color="success"
        :disabled="!isReady"
        @click="next"
      >
        Continue
      </v-btn>
    </div>
  </div>
</template>

<script>
import {useState} from "@/modules/publication-next/composeables/se2a-publication-create-state";
import {computed, inject} from "vue";

export default {
  name: "se2a-publication-create-goals",
  setup() {
    const {units, goals} = useState();
    const next = inject('next');

    const isReady = computed(() => {
      return Object.entries(goals.value).length === units.value.length && !Object.entries(goals.value).some(value => value.includes(null))
    });

    const check = computed(() => {
      return Object.entries(goals.value);
    });

    return {
      next,
      check,
      units,
      goals,
      isReady
    }
  }
}
</script>

<style scoped>

</style>
