<template>
  <div>

    <div class="mb-2">
      <h4>SE²A Metadata</h4>
    </div>

    <div style="max-width: 300px">
      <v-select
        v-model="publication.metadata['status']"
        placeholder="Status"
        :items="publicationStatuses"
        item-text="name"
        item-value="value"
        outlined
        label="Status of this publication"
      />
    </div>

    <div class="ml-2">
      <v-checkbox
        label="Is this publication open access?"
        v-model="publication.metadata['is-open-access']"
        outlined
      ></v-checkbox>
    </div>

    <div class="ml-2">
      <v-checkbox
        v-model="publication.metadata['is-peer-reviewed']"
        label="Is this publication peer reviewed?"
        outlined
      ></v-checkbox>
    </div>

    <v-divider class="mt-2 mb-2" />
    <div class="d-flex justify-end pt-3">
      <v-btn
        color="success"
        @click="next"
        :disabled="!isReady"
      >
        Continue
      </v-btn>
    </div>
  </div>
</template>

<script>
import {useState} from "@/modules/publication-next/composeables/se2a-publication-create-state";
import {inject} from "vue";
import publicationStatuses from "@/modules/publication-next/publicationStatuses";

export default {
  name: "se2a-publication-create-metadata",
  setup() {
    const { publication } = useState();
    const next = inject('next');

    const isReady = () => {

    }

    return {
      publication, next, isReady, publicationStatuses
    }
  }
}
</script>

<style scoped>

</style>
