export default [
  {
    name: 'Planned',
    value: 'planned'
  },
  {
    name: 'Submitted',
    value: 'submitted'
  },
  {
    name: 'in Revision',
    value: 'in-revision'
  },
  {
    name: 'Accepted',
    value: 'accepted'
  },
  {
    name: 'Published',
    value: 'published'
  }
];
