<template>
  <div>
    <div>{{ title }}</div>
    <component
      v-for="field in enrichedFields"
      :is="field.context.component"
      v-bind:key="field.name"
      :context="field.context"
      :model="field.model"
    />
  </div>
</template>

<script>
import {defineComponent, ref, set, toRef, computed} from "vue";
import Intranet from "@/core/intranet";

export default defineComponent({
  name: "se2a-publication-field-editor",
  props: {
    title: {
      type: String,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const processField = (field) => {
      if(!props.model.hasOwnProperty(field.name)) {
        set(props.model, field.name, ref(""));
      }

      const context = {
        name: field.name,
        displayName: field.displayName,
        description: field.description,
        component: Intranet.dynamicForms().getTypeComponentById(field.type),
      }

      return {
        context: context,
        model: toRef(props.model, field.name)
      }
    }

    const enrichedFields = computed(() => props.fields.map(value => processField(value)));
    return { enrichedFields }
  }
});
</script>

<style scoped>

</style>
