<template>
  <div>
    <div>
      <v-stepper v-model="currentStep" flat>
        <v-stepper-header>
          <v-stepper-step step="1">
            General data
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="2">
            Fields
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">
            Goal Selection
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="4">
            SE²A Metadata
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="5">
            Review
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="0">
            <se2a-publication-create-welcome />
          </v-stepper-content>

          <v-stepper-content step="1">
            <se2a-publication-create-general />
          </v-stepper-content>

          <v-stepper-content step="2">
            <se2a-publication-create-fields />
          </v-stepper-content>

          <v-stepper-content step="3">
            <se2a-publication-create-goals />
          </v-stepper-content>

          <v-stepper-content step="4">
            <se2a-publication-create-metadata />
          </v-stepper-content>

          <v-stepper-content step="5">
            <se2a-publication-create-review />
          </v-stepper-content>

        </v-stepper-items>

      </v-stepper>
    </div>

    <v-container fluid>
      <router-view />
    </v-container>
  </div>
</template>

<script>
import Se2aPublicationCreateGeneral from "@/modules/publication-next/page/se2a-publication-create/se2a-publication-create-general";
import Se2aPublicationCreateFields from "@/modules/publication-next/page/se2a-publication-create/se2a-publication-create-fields";
import Se2aPublicationCreateGoals from "@/modules/publication-next/page/se2a-publication-create/se2a-publication-create-goals";
import {provide, defineComponent, ref} from "vue";
import Se2aPublicationCreateWelcome from "@/modules/publication-next/page/se2a-publication-create/se2a-publication-create-welcome";
import Se2aPublicationCreateMetadata
  from "@/modules/publication-next/page/se2a-publication-create/se2a-publication-create-metadata";
import Se2aPublicationCreateReview
  from "@/modules/publication-next/page/se2a-publication-create/se2a-publication-create-review";

export default defineComponent({
  name: "se2a-publication-create",
  components: {
    Se2aPublicationCreateReview,
    Se2aPublicationCreateMetadata,
    Se2aPublicationCreateWelcome,
    Se2aPublicationCreateGoals, Se2aPublicationCreateFields, Se2aPublicationCreateGeneral},
  setup() {
    const currentStep = ref(0);

    provide('next', () => {
      currentStep.value++;
    })

    return {
      currentStep
    }
  }
});
</script>

<style scoped>

</style>
