import { render, staticRenderFns } from "./se2a-publication-create-fields.vue?vue&type=template&id=13df37fc&scoped=true&"
import script from "./se2a-publication-create-fields.vue?vue&type=script&lang=js&"
export * from "./se2a-publication-create-fields.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13df37fc",
  null
  
)

export default component.exports