import {computed, reactive, toRefs, toRef} from 'vue';

// global state
const state = reactive({
  publication: {
    title: '',
    type: '',
    doi: '',
    metadata: {},
    fields: {}
  },
  types: [],
  units: [],
  goals: {}
})

const reset = () => {
  state.publication = {
    title: '',
    type: '',
    doi: '',
    metadata: {},
    fields: {}
  };

  state.goals = {};
  state.units = [];
}

const activeType = computed(() => {
  return state.types.find(value => value['name'] === state.publication['type'])
})


export const useState = () => {
  return {
    ...toRefs(state),
    activeType,
    reset
  }
}
